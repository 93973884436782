import * as React from "react";
import { navigate } from "gatsby-link";

import Layout from "../components/layout";
import Seo from "../components/seo";
import PageTitle from "../components/common/page-title";
import {
  yttc200 as yttc200Bali,
  yttc100hathaAshVin as yttc100hathaAshVinBali,
  yttc100AerialYin as yttc100AerialYinBali,
  yttc50Hatha as yttc50HathaBali,
  yttc50AerialAcro as yttc50AerialAcroBali,
  yttc50Ashtanga as yttc50AshtangaBali,
  yttc15days100HathaAshtanga as yttc15days100HathaAshtangaBali,
  yttc15days100AshtangaAerial as yttc15days100AshtangaAerialBali,
  yttc22days200HathaAshtangaAerialAcro as yttc22days200HathaAshtangaAerialAcroBali,
  yogaHolidaysBaliDates,
  yttc200Bali200Puri,
} from "../data/calendar-data-bali";

const locationToCourse = {
  bali_permana: [
    {
      key: "yttc22days200HathaAshtangaAerialAcroBali",
      value:
        "22 Day 200 Hour Hatha, Ashtanga, Aerial, and Acro Yoga Teacher Training",
    },
    {
      key: "yttc15days100HathaAshtangaBali",
      value:
        "15 Day 100 Hour Traditional Hatha and Ashtanga Yoga Teacher Training",
    },
    {
      key: "yttc15days100AshtangaAerialBali",
      value: "15 Day 100 Hour Ashtanga and Aerial Yoga Teacher Training",
    },
    {
      key: "yttc50HathaBali",
      value: "08 Day 050 Hour Traditional Hatha Yoga Teacher Training",
    },
    {
      key: "yttc50AerialAcroBali",
      value: "08 Day 050 Hour Aerial and Acro Yoga Teacher Training",
    },
    {
      key: "yttc50AshtangaBali",
      value: "08 Day 050 Hour Ashtanga Yoga Teacher Training",
    },
  ],
  bali_puri_suksma: [
    {
      key: "yttc200Bali200Puri",
      value: "200-Hour Hatha, Ashtanga, Vinyasa Yoga Teacher Training",
    },
    {
      key: "yogaHolidaysBali",
      value: "Yoga Holidays",
    },
  ],
  rishikesh: [
    {
      key: "200-yttc-rishikesh",
      value: "200 YTTC",
    },
    {
      key: "100-aerial-yin-rishikesh",
      value: "100 YTTC Aerial Yin",
    },
  ],
};

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Bookings = () => {
  const [state, setState] = React.useState({});
  const [getBookings, setBookings] = React.useState([]);
  const [getCourses, setCourses] = React.useState([]);
  const [isTriple, setTriple] = React.useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (e.target.name === "course") {
      setBookings([]);
      setTriple(false);
      switch (e.target.value) {
        case "200-yttc-bali":
          setBookings(yttc200Bali.dates);
          break;
        case "100-hatha-astanga-bali":
          setBookings(yttc100hathaAshVinBali.dates);
          break;
        case "100-aerial-yin-bali":
          setBookings(yttc100AerialYinBali.dates);
          break;
        case "yttc50HathaBali":
          setBookings(yttc50HathaBali.dates);
          break;
        case "yttc50AerialAcroBali":
          setBookings(yttc50AerialAcroBali.dates);
          break;
        case "yttc50AshtangaBali":
          setBookings(yttc50AshtangaBali.dates);
          break;
        case "yttc15days100HathaAshtangaBali":
          setBookings(yttc15days100HathaAshtangaBali.dates);
          break;
        case "yttc15days100AshtangaAerialBali":
          setBookings(yttc15days100AshtangaAerialBali.dates);
          break;
        case "yttc22days200HathaAshtangaAerialAcroBali":
          setBookings(yttc22days200HathaAshtangaAerialAcroBali.dates);
          setTriple(true);
          break;
        case "yogaHolidaysBali":
          setBookings(yogaHolidaysBaliDates.dates);
          break;
        case "yttc200Bali200Puri":
          setBookings(yttc200Bali200Puri.dates);
          break;
        default:
          setTriple(false);
          break;
      }
    }

    if (e.target.name === "location") {
      switch (e.target.value) {
        case "bali_permana":
          setCourses(locationToCourse.bali_permana);
          setBookings([]);
          break;
        case "bali_puri_suksma":
          setCourses(locationToCourse.bali_puri_suksma);
          setBookings([]);
          break;
        case "rishikesh":
          setCourses(locationToCourse.rishikesh);
          setBookings([]);
          break;
        default:
          break;
      }
    }
  };

  //toggle required based on the choice

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };
  return (
    <Layout>
      <Seo title="Bookings" />
      <div>
        <PageTitle name="Enquiry" />

        <div>
          <form
            name="booking"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            action="/thanks/"
            onSubmit={handleSubmit}
          >
            <p className="hidden">
              <input type="hidden" name="form-name" value="booking" />
              <input name="bot-field" onChange={handleChange} />
            </p>

            <div className="mb-6">
              <label
                htmlFor="location"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select your Location
              </label>
              <select
                id="location"
                name="location"
                required
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5"
              >
                <option></option>
                <option value="bali_permana">Bali, Permana</option>
                {/* <option value="bali_puri_suksma">Bali, Puri Suksma</option> */}
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="course"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select your course
              </label>
              <select
                id="course"
                name="course"
                required
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5"
              >
                <option></option>
                {getCourses.map((item) => (
                  <option key={item.key} value={item.key}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="Dates"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select your booking dates
              </label>
              <select
                id="Dates"
                name="date"
                required
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5"
              >
                <option></option>
                {getBookings.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="room"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select your room type
              </label>
              <select
                id="room"
                name="room"
                required
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5"
              >
                <option>Single</option>
                <option>Twin/Shared</option>
                {isTriple && <option>Triple/Shared</option>}
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="fullname"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Full Name
              </label>
              <input
                type="fullname"
                name="fullname"
                id="fullname"
                maxLength={70}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="gender"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Select your Gender
              </label>
              <select
                id="gender"
                name="gender"
                required
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5"
              >
                <option></option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>
              </select>
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                maxLength={50}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="contact"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Contact number
              </label>
              <input
                type="tel"
                name="contact"
                id="contact"
                maxLength={20}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="city"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                maxLength={50}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="country"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Country
              </label>
              <input
                type="text"
                name="country"
                id="country"
                maxLength={50}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="aboutus"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                How did you hear about us?
              </label>
              <input
                type="text"
                name="aboutus"
                id="aboutus"
                maxLength={100}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Any special request/concerns (max 250 characters)
              </label>
              <textarea
                type="textarea"
                name="message"
                rows="4"
                id="message"
                maxLength={250}
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 "
                required
              />
            </div>

            <button
              type="submit"
              className="text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Send Enquiry
            </button>
          </form>
          <p className="text-base leading-6 text-gray-600 pb-6 mt-6">
            Our replies may land in your spam. Please do check your spam. We
            reply within a maximum of 12 hours. Thank you
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Bookings;
